<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col align="center">
        <div>
          <v-alert
            outlined
            type="info"
            border="top"
            max-width="500"
            class="text-left"
          >
            <b>Modulet for FAQ er foreløbig ikke aktiveret. </b>
            <br>
            Afventer afklaring vedr. klassificering af materialer.
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FAQ",
};
</script>